body {
  background-color: #06090D;

}

.slick-slide {
display: flex !important;
flex-direction: column !important;
align-items: center !important;
}

/* @font-face {
  font-family: Termina;
  src: url("./public/fonts/TerminaTest-Black.otf") format("opentype");
} */
/* @font-face {
  font-family: 'Termina';
  src: local('MyFont'), url('fonts/TerminaTest-Black.otf') format('opentype');
} */