@tailwind base;
@tailwind components;
@tailwind utilities;
/* public/fonts/TerminaTest-Black.otf */

@font-face {
    font-family: Termina;
    src: url("/public/fonts/TerminaTest-Black.otf") format("opentype");
  }

  h6, h1 {
    font-family: Termina !important;
  }

body {
    max-width: 100vw;
    overflow-x: hidden;
}

*, body, html {
  scroll-behavior: smooth !important;
}